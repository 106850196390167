import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import Fonts from "./Font";

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = extendTheme({
  styles: {
    global: {
      body: {
        minWidth: "320px",
        lineHeight: "1.2",
        fonts: {
          heading: `'Pragmatica', , sans-serif`,
          body: `'Pragmatica', , sans-serif`,
        },
        color: "#231F20",
      },
      button: {
        _hover: {
          color: "#F36421",
        },
      },
    },
  },
});

root.render(
  <BrowserRouter>
    <ChakraProvider theme={theme}>
      <Fonts />
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ChakraProvider>
  </BrowserRouter>
);
