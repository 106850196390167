import { Link } from "react-router-dom";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerFooter,
  VStack,
  Text,
} from "@chakra-ui/react";

function Sidebar({ isOpen, onClose, btnRef }) {
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={btnRef}
      height="100vh"
    >
      <DrawerOverlay />
      <DrawerContent bgColor="#231F20">
        <DrawerCloseButton color="#F36421" fontSize="23px" />

        <DrawerBody color="white" mt="30%">
          <VStack align="start">
            <Link to="/">
              <Button variant="link" color="white" fontSize="2xl">
                Главная
              </Button>
            </Link>
            <Link to="/services">
              <Button variant="link" color="white" fontSize="2xl">
                Услуги
              </Button>
            </Link>
            <Link to="/projects">
              <Button variant="link" color="white" fontSize="2xl">
                Проекты
              </Button>
            </Link>
            <Link to="/contact-form">
              <Button variant="link" color="white" fontSize="2xl">
                Связаться с нами
              </Button>
            </Link>
          </VStack>
        </DrawerBody>

        <DrawerFooter>
          <Text as="span" style={{ color: "#fff" }}>
            Info@st-ability.ru
          </Text>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export default Sidebar;
