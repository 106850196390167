import React from "react";
import logoHeader from "../pictures/LogoHeader.svg";
import { Link } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";
import StickyBox from "react-sticky-box";
import Sidebar from "./Sidebar";
import {
  Box,
  Container,
  Flex,
  Image,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";

function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const reloadClick = (e) => {
    e.preventDefault();

    window.location.reload();
  };

  return (
    <>
      <StickyBox style={{ zIndex: "1200" }}>
        <Box as="header" py={2} bgColor="#231F20" w="100%">
          <Container maxW="container.xl">
            <Flex alignItems="center" justifyContent="space-between">
              <Link to="/" onClick={reloadClick}>
                <Image
                  src={logoHeader}
                  alt="stability logo"
                  maxW={["60%", "80%"]}
                />
              </Link>

              <IconButton
                fontSize="35px"
                icon={<HamburgerIcon />}
                color="#F36421"
                bg="#231F20"
                p={0}
                _hover={{
                  bg: "#231F20",
                }}
                _active={{
                  bg: "#231F20",
                }}
                ref={btnRef}
                onClick={onOpen}
              />
            </Flex>
          </Container>
        </Box>
      </StickyBox>

      <Sidebar isOpen={isOpen} onClose={onClose} finalFocusRef={btnRef} />
    </>
  );
}

export default Header;
