import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={
      // `@font-face {
      //   font-family: 'Pragmatica';
      //   src: url('Pragmatica-Bold.eot');
      //   src: local('Pragmatica Bold'), local('Pragmatica-Bold'),
      //       url('Pragmatica-Bold.eot?#iefix') format('embedded-opentype'),
      //       url('Pragmatica-Bold.woff2') format('woff2'),
      //       url('Pragmatica-Bold.woff') format('woff'),
      //       url('Pragmatica-Bold.ttf') format('truetype');
      //   font-weight: bold;
      //   font-style: normal;

      `@font-face {
          font-family: 'Pragmatica';
          src: url('Pragmatica-Medium.eot');
          src: local('Pragmatica Medium'), local('Pragmatica-Medium'),
              url('Pragmatica-Medium.eot?#iefix') format('embedded-opentype'),
              url('Pragmatica-Medium.woff2') format('woff2'),
              url('Pragmatica-Medium.woff') format('woff'),
              url('Pragmatica-Medium.ttf') format('truetype');
          font-weight: 500;
          font-style: normal;
          `
    }
  />
);

export default Fonts;
