import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Loading from "./blocks/Loading";

function Layout() {
  return (
    <>
      <Header />
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
      <Footer />
    </>
  );
}

export default Layout;
