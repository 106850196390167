import { Routes, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import Loading from "./blocks/Loading";
import Layout from "./Layout";

const MainPage = lazy(() => import("./pages/MainPage"));
const SecvicesPage = lazy(() => import("./pages/ServicesPage"));
const ProjectPage = lazy(() => import("./pages/ProjectPage"));
const ProjectsPage = lazy(() => import("./pages/ProjectsPage"));
const ContactUsPage = lazy(() => import("./pages/ContactUsPage"));
const ProjectsMapPage = lazy(() => import("./pages/ProjectsMapPage"));

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loading />}>
              <MainPage />
            </Suspense>
          }
        />
        <Route path="/*" element={<Layout />}>
          <Route path="services" element={<SecvicesPage />} />
          <Route path="projects" element={<ProjectsPage />} />
          <Route
            path="projects/:category/:projectId"
            element={<ProjectPage />}
          />
          <Route path="contact-form" element={<ContactUsPage />} />
          <Route path="projects-map" element={<ProjectsMapPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
