import { Box, Spinner } from "@chakra-ui/react";

function Loading() {
  return (
    <Box w="100%" h="100%" align="center" py="200px">
      <Spinner size="xl" thickness="4px" emptyColor="#5D6170" color="#F36421" />
    </Box>
  );
}

export default Loading;
