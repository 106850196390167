import {
  Box,
  Container,
  Button,
  Flex,
  // Image,
  VStack,
  // HStack,
  Heading,
} from "@chakra-ui/react";
// import tgIcon from "../pictures/tg.svg";
// import vkIcon from "../pictures/vk.svg";
// import instaIcon from "../pictures/insta.svg";
import { Link } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";

function Footer() {
  return (
    <Box as="footer" w="100%" my="10">
      <Container maxW="container.xl">
        <Flex alignItems="center" justifyContent="space-between" wrap="wrap">
          <Heading
            maxW={["40%", "246px"]}
            fontSize={["xl", "2xl"]}
            fontWeight={400}
          >
            Надежность, качество, соблюдение стандартов
          </Heading>
          <Box color="#5D6170">
            {/* <HStack mb="1">
              <Button variant="link" href="#!">
                <Image src={tgIcon} boxSize="30px" />
              </Button>
              <Button variant="link" href="#!">
                <Image src={vkIcon} boxSize="30px" />
              </Button>
              <Button variant="link" href="#!">
                <Image src={instaIcon} boxSize="30px" />
              </Button>
            </HStack> */}
            <form action="mailto:Info@st-ability.ru" method="get">
              <Button variant="link" type="submit">Info@st-ability.ru</Button>
            </form>
          </Box>
          <VStack
            align="start"
            spacing={[1, 2, 3]}
            mt={[3, 0, 0]}
            display={{ base: "none", md: "flex" }}
          >
            <Link to="/">
              <Button variant="link" onClick={ScrollToTop()}>
                Главная
              </Button>
            </Link>
            <Link to="/services">
              <Button variant="link" onClick={ScrollToTop()}>
                Услуги
              </Button>
            </Link>
            <Link to="/projects">
              <Button variant="link" onClick={ScrollToTop()}>
                Проекты
              </Button>
            </Link>
            <Link to="/contact-form">
              <Button variant="link" onClick={ScrollToTop()}>
                Связаться с нами
              </Button>
            </Link>
          </VStack>
        </Flex>
      </Container>
    </Box>
  );
}

export default Footer;
